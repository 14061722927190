import React from 'react';
import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import one from "../images/1.png"
import two from "../images/14D8ADD0-9008-4497-B849-B0C880C0CC2E.jpg"
import three from "../images/44D065E0-56E2-4C9B-9FF5-D1DA4872612B.jpg"
import four from "../images/IMG_3835.jpg"
import five from "../images/IMG_4983.jpg"
import six from "../images/IMG_5085.jpg"
import seven from "../images/IMG_5301.jpg"
import eight from "../images/IMG_5302.jpg"
import nine from "../images/IMG_5303.jpg"
import ten from "../images/IMG_5307.jpg"
import eleven from "../images/IMG_5308.jpg"
import twelve from "../images/IMG_5319.jpg"
import thirteen from "../images/1130_1.jpg";
import fourteen from "../images/1130_2.jpg";
import fifteen from "../images/1130_3.jpg";
import sixteen from "../images/1130_4.jpg";
import "./Hero.css"

export const Carousel1 = () => {
    const [sliderRef] = useKeenSlider({
        loop: true,
        mode: "free-snap",
        slides: {
          perView: 3,
          spacing: 15,
        },
      })
      return (
        <div ref={sliderRef} className="keen-slider">
          <div className="keen-slider__slide number-slide1"><img className='imgslide' src={one} alt="" /></div>
          <div className="keen-slider__slide number-slide2"><img  className='imgslide' src={two} alt="" /></div>
          <div className="keen-slider__slide number-slide3"><img  className='imgslide' src={three} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={four} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={five} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={six} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={seven} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={eight} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={nine} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={ten} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={eleven} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={twelve} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={thirteen} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={fourteen} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={fifteen} alt="" /></div>
          <div className="keen-slider__slide number-slide4"><img  className='imgslide' src={sixteen} alt="" /></div>
        </div>
      )
};
