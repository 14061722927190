import { useState } from 'react';
import React from 'react';
import { RxHamburgerMenu } from 'react-icons/rx';
import "./Navbar.css"
import logo from "../images/logo.gif"

const Navbar = () => {
  const [showMedia, setMedia] = useState(false);

  const handleHamburgerClick = (event) => {
    event.preventDefault();
    setMedia(!showMedia);
    document.body.classList.toggle('menu-open', !showMedia);
  };


  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const yOffset = -90;
      const y = section.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      setMedia(false);
    }
  };


  return (
    <>
      <nav className="navbar">
        {/* {/ {/ logo /} /} */}
        <div className='logo'>
          <a href="/"><img src={logo} alt="logo" /></a>
        </div>

        {/* {/ {/ menu /} /} */}
        <div className={showMedia ? "menu mobile-menu" : "menu"}>
          <ul>
            <li>
              <p onClick={(e) => scrollToSection('About')}>About</p>
            </li>
            <li>
              <p onClick={(e) => scrollToSection('Gallery')}>Gallery</p>
            </li>
            <li>
              <p onClick={(e) => scrollToSection('Services')}>Services</p>
            </li>
            <li>
              <p onClick={(e) => scrollToSection('Applicatons')}> Applications </p>
            </li>
            <li>
              <p onClick={(e) => scrollToSection('Contacts')}> Contacts </p>
            </li>
          </ul>



        </div>



        {/* {/ {/ hamburger menu /} /} */}
        <div className='hamburger'>
          <a href="1" onClick={handleHamburgerClick}>
            <RxHamburgerMenu style={{ color: '#e279e7' }} />
          </a>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
