import React from 'react';
import "./Hero.css"
import Navbar from "./Navbar"
import { Carousel1 } from './GallerySlider';
// import menu1 from "../images/Online menu.png"
// import menu2 from "../images/Inperson menu.png"
// import menu3 from "../images/TPE menu.png"
import { SiOnlyfans,  SiDiscord, SiCashapp, SiTwitter, SiMinutemailer } from "react-icons/si";
import { PiCatFill, PiCloud  } from "react-icons/pi";
import { MdOutlineSwipe } from "react-icons/md";
import { RiHeart3Fill } from "react-icons/ri";
import main from "../images/main.png"
import { Grid, Typography, List, ListItem } from '@mui/material';

export const Hero = () => {
    return (
        <>
            <Navbar />

            <section className="hero" id='About'>
            <h1 className='heading1'>Miss Fox Albany NY Dominatrixs</h1>
                <div>
                    <img src={main} alt="Portrait of Miss Fox with her shoulder length brown hair worn down and dark red lipstick" />
                    <Grid container justifyContent={'center'} alignItems={'center'}>
                      <Typography variant={'h2'} fontStyle={'italic'}>Dominant by Nature -- Big Bodied by Snacks</Typography>
                      <Typography variant={'h5'}>I'm a 31 year old pansexual Sadist Domme. My numbers are 345 lbs. and 46G; make sure you can handle them before you approach me.</Typography>
                      <Typography variant={'body1'}>
                        I have been a Dominant for over 7 years, and currently have a stable with 2 long term submissives. My style is Sadistic with a touch of sweet, and I'm a PRICK ("Personal Responsibility Informed Consensual Kink") practictioner.
                        This means I require you to have a clear understanding of your own limits and concise negotiations where you communicate them and your safewords to me.
                        If you approach, I expect you to demonstrate who you are as a submissive: I want to see intention, eagerness, and clear communication. I will NOT respond to demands.
                      </Typography>
                      <Typography variant={'h4'}>Your safety and my discretion are the top two priorities.</Typography>
                    </Grid>
                </div>
            </section>
            <section className='Gallery' id='Gallery'>
            <h1 className='heading'>Gallery</h1>
                <div className='carousel-div'>
                    <Carousel1 />
                </div>
                <Typography variant={'caption'}>Swipe for More <MdOutlineSwipe size={28} /></Typography>
                <a href="https://www.loyalfans.com/misx_fox" target='blank' style={{textDecoration:"none"}}>
                <button class="btn1" type="button">
                    <strong>Want Spicier Pics?</strong>
                    <div id="container-stars">
                        <div id="stars"></div>
                    </div>

                    <div id="glow">
                        <div class="circle"></div>
                        <div class="circle"></div>
                    </div>
                </button>
                </a>
            </section>

            <section id='disclaimer'>
              <Grid container width={'90%'} margin={'auto'} alignItems={'center'} marginBottom={'2rem'} rowGap={'2rem'}>
                <Typography width={'100%'} variant={'h4'} fontStyle={'italic'} fontWeight={'bold'} fontFamily={'lobster'} textAlign={'center'}>Legal Disclaimer</Typography>
                <Grid container alignItems={'center'} direction={'column'} rowGap={'1rem'}>
                  <Typography variant={'body1'}>
                    Please remember, Professional Dominants are <b>not prostitutes!</b>
                  </Typography>
                  <Typography variant={'body1'}>
                    The Practice of B/D, S/M and sensual sadism isn't prostitution and the offer of <b>sexual services are neither implied or offered here</b>.
                  </Typography>
                  <Typography variant={'body1'}>
                    Asking me or any other professional Dominant for sexual services <b>may constitute solicitation</b> in some states and is illegal.
                  </Typography>
                  <Typography variant={'body1'}>
                    If prostitution is what you desire, do not contact me. You <b>will be blocked</b>.
                  </Typography>
                  <Typography variant={'body1'}>
                    If you choose to contact me, it is understood that you:
                  </Typography>
                  <List>
                      <ListItem>- are an adult -- 21 years of age or age of majority in your jurisdiction, whichever is higher</ListItem>
                      <ListItem>- are responsible for your actions and behavior</ListItem>
                      <ListItem>- are not seeking and will not request prostitution in any form</ListItem>
                      <ListItem>- are seeking the services of a professional dominant of your own free will</ListItem>
                  </List>
                </Grid>
              </Grid>
            </section>

            {/* <section className='Menus' id='Services'>
                <h1 className='heading1'>Services</h1>
                <div>
                    <img src={menu1} alt="" />
                    <img src={menu2} alt="" />
                    <img src={menu3} alt="" />
                </div>
            </section> */}
            
            <section className='Application' id='Applicatons'>
                <h1 className='heading'>Applicaton</h1>
                <h5>To serve, fill out the below mandatory jotform:</h5>
                <a href="https://form.jotform.com/233485634459063" target='blank' style={{textDecoration:"none"}}>
                <button class="btn1" type="button">
                    <strong>KNEEL</strong>
                    <div id="container-stars">
                        <div id="stars"></div>
                    </div>

                    <div id="glow">
                        <div class="circle"></div>
                        <div class="circle"></div>
                    </div>
                </button>
                </a>

            </section>
            <section className='Contact' id='Contacts'>
            <h1 className='heading1'>Contacts</h1>
            <div>
                <div>
                    <a className='logos' href="https://bsky.app/profile/misxfox.bsky.social" target='blank'>
                    <PiCloud/>
                    </a>
                    <h1>Bluesky</h1>
                </div>
                <div>
                    <a className='logos' href="https://twitter.com/misx_fox" target='blank'>
                    <SiTwitter/>
                    </a>
                    <h1>Twitter</h1>
                </div>
                <div>
                    <a className='logos' href="https://cash.app/$foxface23" target='blank' >
                    <SiCashapp/>
                    </a>
                    <h1>Cashapp</h1>
                </div>
                <div>
                    <a className='logos' href="https://discord.gg/GB286zpJzh" target='blank' >
                    <SiDiscord/>
                    </a>
                    <h1>Discord</h1>
                </div>
                <div>
                    <a className='logos' href="mailto:misx_fox@proton.me" target='blank' >
                    <SiMinutemailer/>
                    </a>
                    <h1>Mail</h1>
                </div>
                <div>
                    <a className='logos' href="https://www.loyalfans.com/misx_fox" target='blank' >
                    <SiOnlyfans/>
                    </a>
                    <h1>Loyal Fans</h1>
                </div>
                <div>
                    <a className='logos' href="https://www.sextpanther.com/Misx_Fox" target='blank' >
                    <PiCatFill/>
                    </a>
                    <h1>Sex Panther</h1>
                </div>
                <div>
                    <a className='logos' href="https://tryst.link/bdsm/missfox13" target='blank' >
                    <RiHeart3Fill/>
                    </a>
                    <h1>Tryst</h1>
                </div>
            </div>
            </section>
        </>
    )
}